








































































































import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import MixinsStorage from "@/mixins/single/storage";
import core from "@/core";

export default mixins(MixinsModal, MixinsStorage).extend({
  name: "SelectSearchModal",
  props: {
    items: {
      type: Array,
      default: [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemId: {
      type: String,
      default: "id",
    },
    placeholder: {
      type: String,
      default: "",
    },
    history: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    createFunc: {
      type: Function,
      default: null,
    },
    // 키워드 존재여부 확인 함수
    existsFunc: {
      type: Function,
      default: null,
    },
    // 키워드 존재시 표시할 코멘트
    existsComment: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    storageItems: {
      type: "select-search-modal",
    },
    historyItemList: [] as any,
    searchKeyword: null as string | null,
    filterItems: [] as any,
    showCreateView: false,
    showExistsComment: false,
  }),
  async created() {
    if (this.isNotBlank(this.history)) {
      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItemList = items;
      } else {
        this.setStorageItem(this.history, this.historyItemList);
      }
    }

    this.changedSearchKeyword(this.searchKeyword);
  },
  watch: {
    searchKeyword(val) {
      this.changedSearchKeyword(val);
    },
  },
  methods: {
    changedSearchKeyword(val) {
      this.showCreateView = false;
      this.showExistsComment = false;

      if (val != null && val.length > 0) {
        const items = this.items as any;
        if (Array.isArray(items)) {
          const key = this.itemText as string;
          let isDuplicateName = false;
          this.filterItems = items.filter((item: any) => {
            const value = item[key];
            if (value != null && value.indexOf(val) !== -1) {
              if (value === val) {
                isDuplicateName = true;
              }
              return true;
            }
            return false;
          });
          if (!isDuplicateName && this.createFunc != null) {
            if (this.existsFunc != null && this.existsComment != null && this.existsFunc(val)) {
              this.showExistsComment = true;
            } else {
              this.showCreateView = true;
            }
          }
        }
      } else {
        const items = this.items;
        if (Array.isArray(items)) {
          this.filterItems = items;
        } else {
          this.filterItems = [];
        }
      }
    },
    getText(item: any) {
      const key = this.itemText as string;
      const value = item[key];
      if (value == null) return "";
      return value;
    },
    submit() {
      //console.log("searchKeyword : ", this.searchKeyword);
    },
    onSelect(selectedItem: any) {
      //console.log("item : ", item);
      let item = null as any;
      if (this.isNotBlank(this.history)) {
        const itemId = this.itemId as string;
        const itemText = this.itemText as string;

        this.items.some((_item: any) => {
          if (_item[itemId] === selectedItem[itemId]) {
            item = _item;
            return true;
          }
        });

        this.historyItemList.some((historyItem: any, index: number) => {
          if (historyItem[itemId] === selectedItem[itemId]) {
            this.historyItemList.splice(index, 1);
            return true;
          }
        });

        if (item == null) {
          const name = this.isNotBlank(this.name) ? this.name : "항목";
          core.alert.show({
            title: "알림",
            body: `삭제된 ${name} 입니다`,
          });
          this.setStorageItem(this.history, this.historyItemList);
          return;
        }

        const historyItem = {} as any;
        historyItem[itemId] = item[itemId];
        historyItem[itemText] = item[itemText];
        this.historyItemList.unshift(historyItem);

        const historyItemList = [] as any;
        this.historyItemList.some((historyItem: any, index: number) => {
          historyItemList.push(historyItem);
          if (index === 4) {
            return true;
          }
        });
        this.historyItemList = historyItemList;
        // history 최대 5개만 저장

        this.setStorageItem(this.history, this.historyItemList);
      } else {
        item = selectedItem;
      }

      const id = item[this.itemId];
      this.$emit("update:id", id);
      const text = item[this.itemText];
      this.$emit("update:text", text);
      this.$emit("update:value", item);
      this.cancel();
    },
    async create() {
      if (this.createFunc != null) {
        try {
          let item = await this.createFunc(this.searchKeyword, this.filterItems);
          if (item != null) {
            this.onSelect(item);
          } else {
            const keyword = this.isNotBlank(this.searchKeyword)
              ? (this.searchKeyword as string).trim()
              : "";
            const key = this.itemText;

            this.items.some((_item: any) => {
              const value = _item[key];
              if (this.isNotBlank(value) && value.trim() === keyword) {
                item = _item;
                return true;
              }
            });

            if (item != null) {
              this.onSelect(item);
            } else {
              this.changedSearchKeyword(this.searchKeyword);
            }
          }
        } catch (e) {
          await core.alert.show({
            title: "알림",
            body: e.message,
          });
        }
      }
    },
  },
});
