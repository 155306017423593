








































import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";

import Constant from "@/store/constant";
import core from "@/core";

import { localize } from "vee-validate";
import { UpdateEventType } from "@/models/update-event";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    phone: "휴대전화 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ApplicationShareModal",
  data: () => ({
    formProcessing: false,
    form: {
      phone: "",
    },
  }),
  created() {
    // 생성 후 호출
  },
  mounted() {
    // 페이지 로딩 후 호출
  },
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        try {
          const result = await core.http.post("/api/sms/download-link", this.form);
          console.log("result : ", result);
          this.close(UpdateEventType.CONFIRM);
        } catch (e) {
          this.errorSubmit(e);
        }
      }
    },
  },
});
