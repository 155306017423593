import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","min-width":"300","max-width":"600"},model:{value:(_vm.properties.visible),callback:function ($$v) {_vm.$set(_vm.properties, "visible", $$v)},expression:"properties.visible"}},[_c(VCard,[_c(VToolbar,{attrs:{"elevation":"0"}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-send")])],1),_c(VToolbarTitle,{staticClass:"pl-2"},[_vm._v(" 앱 링크 문자 전송")])],1),_c(VForm,{staticClass:"pa-0",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c(VCardText,{ref:"body",staticClass:"pl-5 pr-5"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|min:9|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"휴대전화","placeholder":"휴대전화 번호를 입력하세요","name":"ceoName","type":"tel","counter":13,"autocomplete":"off","error-messages":errors,"disabled":_vm.formProcessing,"required":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" 취소")]),_c(VBtn,{attrs:{"type":"submit","color":"primary darken-1","text":""}},[_vm._v(" 전송")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }