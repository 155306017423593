













import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/models/update-event";

export default mixins(MixinsModal).extend({
  name: "TimePickerModal",
  props: {
    start: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    keyEvent: null as any,
    time: null as any,
    type: null as string | null,
    allowedMinutes: [0, 10, 20, 30, 40, 50],
  }),
  mounted() {
    const params = this.properties.params;
    if (params != null) {
      if (params.time != null) {
        this.time = params.time;
      }
      if (params.type != null) {
        this.type = params.type;
      }
    }
  },
  methods: {
    confirm() {
      //console.log('confirm');
      this.close(UpdateEventType.CONFIRM, {
        type: this.type,
        time: this.time,
      });
    },
  },
});
