import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class SupportService extends AbstractTableService {
  constructor() {
    super("/api/support", "support");
  }

  updateActivated(id: number, activated: boolean) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify({ activated: activated });
      core.http
        .putJson(`${this.url}/${id}/activated`, strJson)
        .then((data: any) => {
          const item = data[this.itemKey];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createAs(params: any) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`${this.url}/as`, strJson)
        .then((data: any) => {
          const item = data[this.itemKey];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new SupportService();
