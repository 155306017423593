import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"h-100"},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"px-5",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c(VCard,{staticStyle:{"border":"0","box-shadow":"initial"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VImg,{staticClass:"grey lighten-4 rounded-circle",attrs:{"max-width":"100px","max-height":"100px","src":"/img/icons/android-icon-144x144.png","alt":"스마트라인"}})],1),_c(VRow,{staticClass:"text-h6 font-weight-bold mt-4",attrs:{"align":"center","justify":"center"}},[_vm._v(" 스마트라인 ")]),_c(VRow,{staticClass:"text-h5 font-weight-bold blue--text mt-4",attrs:{"align":"center","justify":"center"}},[_vm._v(" 로그인 ")]),_c('ValidationObserver',{ref:"observer"},[_c(VForm,{staticClass:"mt-4 pa-0",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"아이디","placeholder":"아이디를 입력하세요","name":"username","type":"text","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.formProcessing,"required":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:4|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":"비밀번호","placeholder":"비밀번호를 입력하세요","name":"password","type":"password","counter":20,"autocomplete":"off","error-messages":errors,"disabled":_vm.formProcessing,"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_c(VCheckbox,{attrs:{"label":"로그인 정보 저장","disabled":_vm.formProcessing},model:{value:(_vm.form.rememberMe),callback:function ($$v) {_vm.$set(_vm.form, "rememberMe", $$v)},expression:"form.rememberMe"}}),_c(VLayout,{attrs:{"row":"","wrap":"","align-center":""}},[_c(VFlex,{staticClass:"mt-4 pl-2 pr-2"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.formProcessing,"disabled":_vm.formProcessing,"min-width":"100%"},on:{"click":_vm.submit}},[_vm._v("로그인 ")]),_c(VBtn,{staticClass:"white--text mt-2",attrs:{"type":"button","color":"grey darken-2","loading":_vm.formProcessing,"disabled":_vm.formProcessing,"to":"/register","min-width":"100%"}},[_vm._v("회원가입 ")])],1)],1),_c(VLayout,{attrs:{"row":"","wrap":"","align-center":""}},[_c(VFlex,{staticClass:"mt-4 pl-2 pr-2"},[_c(VBtn,{staticClass:"mt-2 login-btn kakao-login-btn",attrs:{"type":"button","loading":_vm.formProcessing,"disabled":_vm.formProcessing,"min-width":"100%"},on:{"click":_vm.loginKakao}},[_c('img',{staticClass:"login-icon-img",attrs:{"src":"/img/login/kakao_login_symbol.png","alt":""}}),_vm._v(" 카카오 로그인 ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }