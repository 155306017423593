import store from "./index";

export const namespaced = true;

export interface Filter {
  type: string;
  filter: any;
}

export interface SessionItem {
  type: string;
  items: any;
}

export interface StorageItem {
  type: string;
  items: any;
}

export const state = {
  filters: new Map(),
  sessionItems: new Map(),
  storageItems: new Map(),
};

export const actions = {
  setFilter({ commit }: any, params: Filter) {
    const user = (store.state as any).auth.user;

    const key = `${user.id}_filter_${params.type}`;
    const value = JSON.stringify(params.filter);
    //console.log(`localStorage update item key : ${key}, value : ${value}`);
    localStorage.setItem(key, value);

    commit("filter", { type: key, filter: params.filter });
  },
  getFilter({ commit }: any, type: string) {
    if (type == null) {
      //console.log("invalid filter type : " + type);
      return null;
    }
    const user = (store.state as any).auth.user;
    const key = `${user.id}_filter_${type}`;
    const filter = state.filters.get(key);
    if (filter != null) {
      return filter;
    } else {
      //console.log(`localStorage get item key : ${key}`);
      const value = localStorage.getItem(key);
      if (value != null) {
        const filters = JSON.parse(value);
        commit("filter", filters);
        return filters;
      }
      return null;
    }
  },
  setSessionItem({ commit }: any, params: SessionItem) {
    const user = (store.state as any).auth.user;

    const key = `${user.id}_session_${params.type}`;
    const value = JSON.stringify(params.items);
    //console.log(`sessionStorage update item key : ${key}, value : ${value}`);
    sessionStorage.setItem(key, value);

    commit("session", { type: key, items: params.items });
  },
  getSessionItem({ commit }: any, type: string) {
    if (type == null) {
      console.log("invalid session type : " + type);
      return null;
    }
    const user = (store.state as any).auth.user;
    const key = `${user.id}_session_${type}`;
    const items = state.sessionItems.get(key);
    if (items != null) {
      return items;
    } else {
      // console.log(`sessionStorage get item key : ${key}`);
      const value = sessionStorage.getItem(key);
      if (value != null) {
        const items = JSON.parse(value);
        commit("session", items);
        return items;
      } else {
        const items = {} as any;
        actions.setSessionItem({ commit: commit }, { type: type, items: items });
        commit("session", items);
        return items;
      }
    }
  },

  setStorageItem({ commit }: any, params: StorageItem) {
    const user = (store.state as any).auth.user;

    const key = `${user.id}_storage_${params.type}`;
    const value = JSON.stringify(params.items);
    //console.log(`storage update item key : ${key}, value : ${value}`);
    localStorage.setItem(key, value);

    commit("storage", { type: key, items: params.items });
  },
  getStorageItem({ commit }: any, type: string) {
    if (type == null) {
      console.log("invalid storage type : " + type);
      return null;
    }
    const user = (store.state as any).auth.user;
    const key = `${user.id}_storage_${type}`;
    const items = state.storageItems.get(key);
    if (items != null) {
      // console.log(`storage get item key : ${key}, value : ${items}`);
      return items;
    } else {
      // console.log(`storage get item key : ${key}`);
      const value = localStorage.getItem(key);
      if (value != null) {
        const items = JSON.parse(value);
        commit("storage", items);
        return items;
      } else {
        const items = {} as any;
        actions.setStorageItem({ commit: commit }, { type: type, items: items });
        commit("storage", items);
        return items;
      }
    }
  },
};

export const mutations = {
  filter(state: any, params: Filter) {
    state.filters.set(params.type, params.filter);
  },
  session(state: any, params: SessionItem) {
    state.sessionItems.set(params.type, params.items);
  },
  storage(state: any, params: StorageItem) {
    state.storageItems.set(params.type, params.items);
  },
};
