import qs from "qs";
import core from "@/core";

class UserService {
  register(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/register/manager`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  login(params: object) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/login`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getSocialToken(token: string) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/social/token`, null, { value: token })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.social != null) {
            resolve(data.social);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialRegister(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/social/register/manager`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  socialLogin(registrationId: string, addProvider: boolean | null, params: any) {
    if (addProvider) {
      params.addProvider = addProvider;
    }
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/social/${registrationId}`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/logout`)
        .then((data: any) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params: { phone: string; name: string; email: string }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/user/me`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updatePassword(params: {
    newPassword: string;
    newConfirmPassword: string;
    currentPassword: string;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/user/me/password`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMobileDeviceInfo(params: {
    crudType: string;
    deviceToken: string;
    timezone: string | null;
    locale: string | null;
    manufacturer: string | null;
    model: string | null;
    product: string | null;
    hardware: string | null;
    os: string | null;
    versionSdk: number | null;
    versionRelease: string | null;
    packageName: string | null;
    versionCode: number | null;
    versionName: string | null;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/me/mobile-device`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserService();
