import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  validate = {
    language: {
      korea: korea,
    },
  };
  map = {
    kakaoRestApiKey: "8a4dbcef128af44068e1b550873624e1",
    kakaoJsKey: "9c6ae8f438f92f360dcb6be3dcf43764",
  };
}

export default new Constant();
