import Stomp, { Client, Subscription } from "webstomp-client";
import SockJS from "sockjs-client";
import store from "@/store";
import { EntityType } from "@/models/entity-type";

export enum Status {
  CONNECTED,
  CONNECTING,
  DISCONNECTED,
}

export enum SubscribeType {
  COMMENT = "comment",
  NOTIFICATION = "notification",
  SUPPORT = "support",
  CALL = "call",
}

const SubscribeTopic = {
  comment: {
    topic: "/topic/comment/{param1}/{param2}",
    paramNameList: ["entityType", "entityId"],
  },
  notification: {
    topic: "/topic/user/{param1}/notification",
    paramNameList: ["userId"],
  },
  support: {
    topic: "/topic/support/{param1}",
    paramNameList: ["companyId"],
  },
  call: {
    topic: "/topic/call/{param1}",
    paramNameList: ["companyId"],
  },
};

export interface SubscribeRequest {
  callback?: any;
  connected?: any;
}

export interface NotificationRequest extends SubscribeRequest {
  userId: number;
}

export interface CommentRequest extends SubscribeRequest {
  entityType: EntityType;
  entityId: number;
}

export interface SupportRequest extends SubscribeRequest {
  companyId: number;
}

class Subscribe {
  type: SubscribeType | null = null;
  topic: string | null = null;
  item: any = null;
  subscription: Subscription | undefined;
}

class CoreWebSocket {
  status = Status.DISCONNECTED;
  client: Client | undefined;
  subscribeList = [] as Subscribe[];

  init() {
    this.connect(this);
  }

  async connect(vm) {
    if (vm.status === Status.DISCONNECTED) {
      const user = await store.dispatch("auth/getUser", false);
      if (user != null) {
        console.log("try web socket connect");
        vm.status = Status.CONNECTING;
        const socket = new SockJS("/ws");
        vm.client = Stomp.over(socket, { debug: false });
        vm.client.connect(
          {},
          (frame) => {
            vm.connected(vm, frame);
          },
          (event) => {
            vm.disconnected(vm, event);
          }
        );
      } else {
        vm.disconnected(vm, null);
      }
    }
  }

  disconnect() {
    const vm = this;
    if (vm.status === Status.CONNECTED) {
      vm.client?.disconnect(() => {
        vm.disconnected(vm, null);
      });
    }
    vm.subscribeList = [];
  }

  private connected(vm, frame) {
    vm.status = Status.CONNECTED;
    console.log("websocket connected");
    const subscribeList = vm.subscribeList;
    vm.subscribeList = [];
    subscribeList.forEach((subscribe) => {
      vm.subscribe(subscribe.type, subscribe.item);
      if (subscribe.connected != null) {
        subscribe.connected();
      }
    });
  }

  private async disconnected(vm, event) {
    vm.status = Status.DISCONNECTED;
    console.log("websocket disconnected");

    const user = (store.state as any).auth.user;
    if (user != null) {
      setTimeout(() => {
        vm.connect(vm);
      }, 5000);
    }
  }

  subscribe(type: SubscribeType, item: NotificationRequest | CommentRequest | SupportRequest) {
    //console.log(`subscribe type '${type}', item '`, item, `'`);

    const topic = this.getTopic(type, item);
    const subscribeList = this.subscribeList;
    for (let i = 0; i < subscribeList.length; i++) {
      const subscribe = subscribeList[i];
      if (subscribe.topic === topic) {
        //console.log("already subscribe topic : ", topic);
        return;
      }
    }
    console.log("subscribe topic : ", topic);
    const subscribe = new Subscribe();
    subscribe.topic = topic;
    subscribe.type = type;
    subscribe.item = item;

    if (this.status === Status.CONNECTED) {
      subscribe.subscription = this.client?.subscribe(topic, (message) => {
        if (item.callback != null) {
          try {
            item.callback(JSON.parse(message.body));
          } catch (e) {
            console.log(e);
          }
        }
      });
    } else {
      console.log("websocket is not connected");
    }
    this.subscribeList.push(subscribe);
  }

  unsubscribe(type: SubscribeType, item: NotificationRequest | CommentRequest | SupportRequest) {
    const topic = this.getTopic(type, item);
    this.subscribeList.some((subscribe, index) => {
      if (subscribe.topic === topic) {
        if (subscribe.subscription != null) {
          console.log("unsubscribe topic : ", topic);
          subscribe.subscription.unsubscribe();
        }
        this.subscribeList.splice(index, 1);
        return true;
      }
    });
  }

  private getTopic(
    type: SubscribeType,
    item: NotificationRequest | CommentRequest | SupportRequest
  ) {
    const subscribeTopicItem = SubscribeTopic[type];
    if (subscribeTopicItem == null) {
      console.log(`Unknown type ${type}`);
      throw new Error("Unknown subscribe type '" + type + "'");
    }
    let topic = subscribeTopicItem.topic;
    subscribeTopicItem.paramNameList.forEach((id, index) => {
      const value = item[id];
      if (value == null) {
        console.log(`subscribe item not found value(key '${id}')`);
        return;
      }
      topic = topic.replace(`{param${index + 1}}`, item[id]);
    });
    return topic;
  }
}

export default new CoreWebSocket();
