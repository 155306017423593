import Vue from "vue";

/**
 * 스크롤 공통 처리
 */
export enum ScrollPosition {
  TOP = "top",
  BOTTOM = "bottom",
  RELOAD = "reload",
}

const debug = false;

export default Vue.extend({
  data: () => ({
    // 스크롤 상태 저장
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
      interval: null as any,
      intervalCount: 0,
      control: {
        scrollTop: 0,
        date: null as any as Date | null,
      },
    },
  }),
  created() {
    if (this.scroll.enable) {
      const scroll = this.scroll;
      const $vnode = this.$vnode;
      const $refs = this.$refs;
      const setScrollTop = this.setScrollTop;
      scroll.eventFunc = function () {
        const $el = $refs.body as HTMLElement;
        if ($el != null) {
          if (scroll.control.date != null) {
            if (scroll.control.scrollTop != $el.scrollTop) {
              const curDate = new Date();
              const diffMillis = curDate.getTime() - scroll.control.date.getTime();
              if (diffMillis < 1000 && $el.scrollTop == 0) {
                // 1초내 다른 스크롤 이벤트 발생시 이전 스크롤 제어값으로 다시 제어
                $el.scrollTop = scroll.control.scrollTop;
                setScrollTop(scroll.control.scrollTop);
                if (debug) {
                  console.log($vnode.tag + ", 스크롤 다시 제어 : ", scroll.control.scrollTop);
                }
                return;
              }
            }
          }
          scroll.scrollTop = $el.scrollTop;
          if (debug) {
            console.log($vnode.tag + ", 스크롤 이벤트 : ", scroll.scrollTop);
          }
        }
      };
    }
  },
  activated() {
    const $el = this.$refs.body as HTMLElement;
    if ($el != null) {
      if (this.scroll.eventFunc) {
        if (debug) {
          console.log("scroll activated. ", this.$vnode.tag);
        }
        const scroll = this.scroll;

        if (debug) {
          console.log("스크롤 이벤트 리스너 등록. ", this.$vnode.tag);
        }
        if (this.$refs.body != null) {
          (this.$refs.body as HTMLElement).addEventListener("scroll", this.scroll.eventFunc);
        }
        if (scroll.first) {
          scroll.first = false;
          this.setScrollTop(0);
        } else {
          this.setScrollPosition(ScrollPosition.RELOAD);
        }
      }
    }
  },
  deactivated() {
    const $el = this.$refs.body as HTMLElement;
    if ($el != null) {
      if (this.scroll.eventFunc) {
        if (debug) {
          console.log("scroll deactivated. ", this.$vnode.tag);
        }
        if (debug) {
          console.log("스크롤 이벤트 리스너 삭제. ", this.$vnode.tag);
        }
        $el.removeEventListener("scroll", this.scroll.eventFunc);
      }
    } else {
      console.log("not found body, ", this.$vnode.tag);
    }
  },
  destroyed() {
    const $el = this.$refs.body as HTMLElement;
    if ($el != null) {
      if (this.scroll.eventFunc) {
        if (debug) {
          console.log("scroll destroyed. ", this.$vnode.tag);
        }
        if (debug) {
          console.log("destroyed. 스크롤 이벤트 리스너 삭제. ", this.$vnode.tag);
        }
        $el.removeEventListener("scroll", this.scroll.eventFunc);
      }
    }
  },
  methods: {
    setScrollTop(scrollTop: number) {
      const $el = this.$refs.body as HTMLElement;
      if ($el != null) {
        if (this.scroll.eventFunc) {
          if (this.scroll.control != null && this.scroll.control.scrollTop == -1) {
            const curDate = new Date();
            const controlDate = this.scroll.control.date as Date;
            const diffMillis = curDate.getTime() - controlDate.getTime();
            if (diffMillis < 1000) {
              if (debug) {
                console.log("ignore event setScrollTop : ", scrollTop);
              }
              return;
            }
          }
          if (debug) {
            console.log("setScrollTop : ", scrollTop);
          }
          const scroll = this.scroll;

          if (scroll.interval != null) {
            clearInterval(scroll.interval);
            scroll.interval = null;
            if (debug) {
              console.log("스크롤 제어중. ", this.$vnode.tag);
            }
          }

          const intervalFunc = () => {
            if ($el != null && $el.offsetParent != null) {
              $el.scrollTop = scrollTop;
              if (debug) {
                console.log(
                  `스크롤제어!!! scrollTop : ${scrollTop}, curScrollTop : ${$el.scrollTop}`
                );
              }
              if (scrollTop <= $el.scrollTop) {
                scroll.intervalCount = 0;
                clearInterval(scroll.interval);
                scroll.interval = null;

                if (scroll.control.scrollTop !== scrollTop) {
                  scroll.control.date = new Date();
                  scroll.control.scrollTop = scrollTop;
                } else if (scroll.control.date != null) {
                  const curDate = new Date();
                  const diffMillis = curDate.getTime() - scroll.control.date.getTime();
                  if (diffMillis > 1000) {
                    scroll.control.date = new Date();
                    scroll.control.scrollTop = scrollTop;
                  }
                }
              }
            }

            if (scroll.intervalCount >= 50) {
              clearInterval(scroll.interval);
              scroll.intervalCount = 0;
              scroll.interval = null;
              if (debug) {
                console.log(
                  `스크롤 변경 실패, 현재 : ${$el.scrollHeight}, ${$el.clientHeight}, ${$el.scrollTop}, 변경요청 : ${scrollTop}`
                );
              }
            }
            scroll.intervalCount++;
          };

          scroll.interval = setInterval(intervalFunc, 10);
          intervalFunc();
        }
      } else {
        console.log("not found body, ", this.$vnode.tag);
      }
    },
    setScrollPosition(scrollPosition: ScrollPosition) {
      const $el = this.$refs.body as HTMLElement;
      if ($el != null) {
        if (this.scroll.eventFunc) {
          if (debug) {
            console.log(`setScrollPosition : ${scrollPosition}`);
          }
          if (scrollPosition == ScrollPosition.TOP) {
            this.setScrollTop(-1);
          } else if (scrollPosition == ScrollPosition.BOTTOM) {
            // console.log("scrollHeight : ", $el.scrollHeight, ", clientHeight : ", $el.clientHeight);
            if ($el.scrollHeight > $el.clientHeight) {
              const scrollTop = $el.scrollHeight - $el.clientHeight;
              if (debug) {
                console.log("bottom scrollTop : ", scrollTop);
              }
              this.setScrollTop(scrollTop);
            } else {
              if (debug) {
                console.log(this.$vnode.tag + ", 스크롤 없음");
              }
            }

            // const el = this.$vnode.elm as any;
            // if (el.scrollHeight > $el.clientHeight) {
            //   const scrollTop = $el.scrollHeight - $el.clientHeight;
            //   if (debug) {
            //     console.log("bottom scrollTop : ", scrollTop);
            //   }
            //   const scrollTop = $el.scrollHeight;
            //   this.setScrollTop(scrollTop);
            // } else {
            //   if (debug) {
            //     console.log(this.$vnode.tag + ", 스크롤 없음");
            //   }
            // }
          } else if (scrollPosition == ScrollPosition.RELOAD) {
            if ($el.scrollTop != this.scroll.scrollTop) {
              this.setScrollTop(this.scroll.scrollTop);
            } else {
              if (debug) {
                console.log(this.$vnode.tag + ", 스크롤 이전 상태와 동일");
              }
            }
          } else {
            console.log("unknown scrollPosition : ", scrollPosition);
          }
        }
      } else {
        console.log("not found body, ", this.$vnode.tag);
      }
    },
  },
});
