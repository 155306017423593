




import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

export default mixins(MixinsPageForm).extend({
  name: "Logout",
  data: () => ({
    done: false,
  }),
  created() {
    // 모든 화면이 렌더링된 후 실행합니다.
    this.$store
      .dispatch("auth/logout")
      .then((data) => {
        this.done = true;
        this.goBack();
      })
      .catch((reason) => {
        this.done = true;
        this.goBack();
      });
  },
  activated() {
    if (this.done) {
      this.goBack();
    }
  },
});
