






































































































































import mixins from "vue-typed-mixins";
import ComponentTemplate from "./ComponentTemplate.vue";
import core from "@/core";

export default mixins(ComponentTemplate).extend({
  name: "SccPageTemplate",
  props: {
    elevation: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "",
    },
    visibleSort: {
      type: Boolean,
      default: false,
    },
    visibleFilter: {
      type: Boolean,
      default: false,
    },
    filterBadgeContent: {
      type: String,
      default: "",
    },
    visibleSearch: {
      type: Boolean,
      default: false,
    },
    visibleAlarm: {
      type: Boolean,
      default: false,
    },
    visibleSetting: {
      type: Boolean,
      default: false,
    },
    visibleDownload: {
      type: Boolean,
      default: false,
    },
    menuType: {
      type: String,
      default: "back", // back, navigation
    },
  },
  data: () => ({
    menu: "",
  }),
  methods: {
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
  },
  updated() {
    this.$emit("update:updated", true);
  },
});
