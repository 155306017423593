import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","min-width":"300","max-width":"600"},model:{value:(_vm.properties.visible),callback:function ($$v) {_vm.$set(_vm.properties, "visible", $$v)},expression:"properties.visible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.properties.title))]),_c(VCardText,{attrs:{"color":"rgba(0, 0, 0, 0.8)"},domProps:{"innerHTML":_vm._s(_vm.properties.body)}}),_c(VCardActions,[_c(VSpacer),(_vm.properties.showCancelButton)?_c(VBtn,{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.properties.cancelButtonText)+" ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.properties.confirmButtonText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }