import Vue from "vue";
import Vuex from "vuex";
import * as auth from "./auth.module";
import * as storage from "./storage.module";
import Constant from "./constant";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    storage,
  },
  state: {
    app: {
      fromRoute: null as any,
      size: {
        width: 0,
        height: 0,
      },
      modal: {
        alert: {
          visible: false,
          title: "알림",
          body: "내용",
          showCancelButton: false,
          cancelButtonText: "취소",
          confirmButtonText: "확인",
          promise: {
            resolve: {},
            reject: {},
          },
          allowBackCloseEvent: true,
        },
      },
      constant: Constant,
    },
    routerBeforeTask: new RouterBeforeTask(),
  },
  actions: {},
});
