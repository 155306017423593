














import Vue from "vue";

export default Vue.extend({
  name: "SccFilterToolBar",
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    removeFilterFunc: {
      type: Function,
      default: () => null,
    },
    addFilterFunc: {
      type: Function,
      default: () => null,
    },
    elevation: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    // 스크롤 상태 저장
    item: {
      extensionEl: null as Element | null,
    },
  }),
  // mounted() {
  //   this.$nextTick(() => {
  //     const elVm = this.$refs.toolbar as Vue | null;
  //     if (elVm != null) {
  //       const $el = elVm.$el as HTMLElement;
  //       if ($el.parentElement != null && $el.parentElement.classList.contains("filter-extension")) {
  //         //console.log($el.parentElement.classList);
  //         const appBarEl = document.querySelector("header.v-app-bar");
  //         if (appBarEl != null) {
  //           console.log("appBar height : ", appBarEl.clientHeight);
  //           $el.style.top = appBarEl.clientHeight + "px";
  //         }
  //       }
  //     }
  //   });
  // },
});
