import UserService from "@/services/user/user.service";
import core from "@/core";

export const namespaced = true;

export interface SystemProperties {
  timezone: string;
  locale: string;
  manufacturer: string;
  model: string;
  product: string;
  hardware: string;
  os: string;
  versionSdk: number;
  versionRelease: string;
  deviceToken: string;
  packageName: string;
  versionCode: number;
  versionName: string;
}

interface State {
  user: object | null;
  sessionCheckTime: Date | null;
  systemProperties: SystemProperties | null;
  mobileSettings: any | null;
  paymentSmsSettings: any | null;
}

export const state: State = {
  user: null,
  sessionCheckTime: null,
  systemProperties: null,
  mobileSettings: null,
  paymentSmsSettings: null,
};

export const actions = {
  async getUser({ commit }: any, reload: boolean | null) {
    //console.log("getUser");
    if (reload == null) reload = false;

    const curTime = new Date();
    if (state.sessionCheckTime == null) {
      state.sessionCheckTime = new Date(0);
    }

    if (!reload) {
      //console.log("call getUser");
      const elapsedTimeMillis = curTime.getTime() - state.sessionCheckTime.getTime();
      //console.log(elapsedTimeMillis);
      if (elapsedTimeMillis < 60000) return state.user;
    }

    try {
      state.sessionCheckTime = curTime;
      const user: any = await UserService.getMyInfo();
      if (user.roles.indexOf("MANAGER") > -1) {
        commit("login", user);
        return user;
      } else {
        console.log("is not support role : ", user.roles);
        actions.logout({ commit: commit });
      }
    } catch (reason) {
      if (reason === 401) {
        actions.logout({ commit: commit });
      }
    }
    return null;
  },
  async login({ commit }: any, params: any) {
    console.log("try login");
    const result = await UserService.login(params);
    state.sessionCheckTime = new Date(0);
    const user = await actions.getUser({ commit: commit }, true);
    if (user != null) {
      return user;
    }
    core.alert.show({
      title: "알림",
      body: "아이디 또는 비밀번호가 올바르지 않습니다.",
      confirmButtonText: "확인",
    });
    throw new Error("Unknown Error");
  },
  async socialLogin({ commit }: any, params: any) {
    const data: any = await UserService.socialLogin(params.registrationId, params.addProvider, {
      accessToken: params.accessToken,
      rememberMe: params.rememberMe,
    });
    if (data.result) {
      state.sessionCheckTime = new Date(0);
      const user = await actions.getUser({ commit: commit }, true);
      if (user != null) {
        return data;
      }
      core.alert.show({
        title: "알림",
        body: "아이디 또는 비밀번호가 올바르지 않습니다.",
        confirmButtonText: "확인",
      });
      throw new Error("Unknown Error");
    } else {
      return data;
    }
  },

  updateMobileSystemProperties({ commit }: any, systemProperties: SystemProperties) {
    if (core.mobile.isApp()) {
      // delete mobile device info
      const params: any = core.utils.deepCopy(systemProperties);
      params.crudType = "C";
      UserService.updateMobileDeviceInfo(params);

      commit("mobileSystemProperties", systemProperties);
    }
  },

  updateMobileSettings({ commit }: any, mobileSettings: any) {
    if (core.mobile.isApp()) {
      // delete mobile device settings
      commit("mobileSettings", mobileSettings);
    }
  },
  updatePaymentSmsSettings({ commit }: any, settings: any) {
    if (core.mobile.isApp()) {
      // delete mobile device settings
      commit("paymentSmsSettings", settings);
    }
  },

  async register({ commit }: any, params: any) {
    return await UserService.register(params);
  },

  async socialRegister({ commit }: any, params: any) {
    return await UserService.socialRegister(params);
  },

  async logout({ commit }: any) {
    if (state.user != null) {
      try {
        if (core.mobile.isApp()) {
          if (state.user != null) {
            // delete mobile device info
            const params: any = {
              crudType: "D",
              deviceToken: state.systemProperties?.deviceToken,
            };
            await UserService.updateMobileDeviceInfo(params);
          }
        }
        return await UserService.logout();
      } catch (e) {
        console.log(e);
      } finally {
        commit("logout");
      }
    }
  },
};

export const mutations = {
  login(state: any, user: any) {
    //console.log('mutations[login]: ', user);
    console.log("update user info");
    const preUser = state.user;
    state.sessionCheckTime = new Date();
    state.user = user;
    if (core.mobile.isApp()) {
      //console.log("call cookieSync");
      core.mobile.call({ cmd: "cookieSync" });
      if (preUser == null) {
        //console.log("call systemProperties");
        core.mobile.call({ cmd: "getSystemProperties" });
        core.mobile.call({ cmd: "refreshUserToken" });
        core.mobile.call({ cmd: "getMobileSettings" });
      }
    }

    core.webSocket.init();
  },
  logout(state: any) {
    //console.log('mutations[logout]: ', state.user);
    if (state.user != null) {
      console.log("logout!");
      state.sessionCheckTime = new Date();
      state.user = null;

      if (core.mobile.isApp()) {
        core.mobile.call({ cmd: "cookieSync" });
        core.mobile.call({ cmd: "refreshUserToken" });
        this.mobileSystemProperties(state, null);
      }
      console.log("done");
    }

    core.webSocket.disconnect();
  },
  mobileSystemProperties(state: any, systemProperties: SystemProperties | null) {
    //console.log('mutations[logout]: ', state.user);
    state.systemProperties = systemProperties;
    if (systemProperties != null) {
      console.log("update systemProperties : ", JSON.stringify(systemProperties));
    }
  },
  mobileSettings(state: any, mobileSettings: any | null) {
    //console.log('mutations[logout]: ', state.user);
    state.mobileSettings = mobileSettings;
    if (mobileSettings != null) {
      console.log("update mobileSettings : ", JSON.stringify(mobileSettings));
    }
  },
  paymentSmsSettings(state: any, settings: any | null) {
    //console.log('mutations[logout]: ', state.user);
    state.paymentSmsSettings = settings;
    if (settings != null) {
      console.log("update payment sms settings : ", JSON.stringify(settings));
    }
  },
  // userCompany(state: any, userCompany: any) {
  //   //console.log('mutations[logout]: ', state.user);
  //   console.log("update user company");
  //   state.userCompany = userCompany;
  //   console.log("user company : ", userCompany);
  // },
};
