export enum Page {
  DASHBOARD = "dashboard",
  ESTIMATE = "estimate",
  AS = "as",
  PLACE = "place",
  UNIT_PRICE = "unit-price",
  SPECIAL_PRICE = "special-price",
  SCOPE = "scope",
  CATEGORY = "category",
  USER = "user",
  TEAM = "team",
  SCHEDULE = "schedule",
  PAYMENT_SMS = "payment-sms",
}
