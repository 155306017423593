





















































































































































import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CallService from "@/services/call/call.service";
import { SubscribeType } from "@/core/core-web-socket";
import SupportService from "@/services/support/support.service";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: {},
  data: () => ({
    supportSize: 0,
    supportMap: new Map(),
    callList: [
      {
        status: "",
        incomingPhone: "07047621876",
        outgoingPhone: "",
        customerList: [],
        date: null as any,
        dateText: "",

        callingAt: "",
        callingAtText: "",
        startCallAt: "",
        startCallAtText: "",
        endCallAt: "",
        endCallAtText: "",
        absCallAt: "",
        absCallAtText: "",
        busyCallAt: "",
        busyCallAtText: "",
        analysis: null,
      },
      {
        status: "",
        incomingPhone: "07047621877",
        outgoingPhone: "",
        customerList: [],
        date: null as any,
        dateText: "",

        callingAt: "",
        callingAtText: "",
        startCallAt: "",
        startCallAtText: "",
        endCallAt: "",
        endCallAtText: "",
        absCallAt: "",
        absCallAtText: "",
        busyCallAt: "",
        busyCallAtText: "",
        analysis: null,
      },
    ],
  }),
  created() {
    // 생성 후 호출
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.subscribe();

      for (const call of this.callList) {
        const result = (await CallService.getTable({
          draw: 0,
          start: 0,
          length: 1,
          orderColumnName: "updatedAt",
          order: "desc",
          searchColumns: {
            activated: true,
            incomingPhone: call.incomingPhone,
          },
        })) as any;
        call.analysis = null;
        result.data.forEach((data) => {
          core.utils.copy(data, call);
          if (data.data != null && data.data.customerList) {
            call.customerList = data.data.customerList;
          } else {
            call.customerList = [];
          }
        });
      }

      for (const call of this.callList) {
        if (this.isBlank(call.status)) {
          const result = (await CallService.getTable({
            draw: 0,
            start: 0,
            length: 1,
            orderColumnName: "updatedAt",
            order: "desc",
            searchColumns: {
              activated: false,
              incomingPhone: call.incomingPhone,
            },
          })) as any;
          result.data.forEach((data) => {
            core.utils.copy(data, call);
            if (data.data != null && data.data.customerList) {
              call.customerList = data.data.customerList;
            } else {
              call.customerList = [];
            }
          });
        }
      }

      const params = {
        draw: 0,
        start: 0,
        length: 1000,
        orderColumnName: "createdAt",
        order: "desc",
        searchColumns: {
          commentCountGreaterThan: 0,
          activated: true,
        },
      };
      const response = (await SupportService.getTable(params)) as any;
      // console.log("response : ", response);
      response.data.forEach((data) => {
        this.supportMap.set(data.estimateId, data);
      });
      this.supportSize = this.supportMap.size;
    });
    const vm = this;
    setInterval(() => {
      vm.callList.forEach((call: any) => {
        if (call.callingAt) {
          call.callingAtText = vm.dateElapsedTimeFormat(call.callingAt);
        }
        if (call.startCallAt) {
          call.startCallAtText = vm.dateElapsedTimeFormat(call.startCallAt);
        }
        if (call.endCallAt) {
          call.endCallAtText = vm.dateElapsedTimeFormat(call.endCallAt);
        }
        if (call.absCallAt) {
          call.absCallAtText = vm.dateElapsedTimeFormat(call.absCallAt);
        }
        if (call.busyCallAt) {
          call.busyCallAtText = vm.dateElapsedTimeFormat(call.busyCallAt);
        }
        //console.log("call : ", call);
      });
    }, 500);
  },
  activated() {
    this.$nextTick(() => {
      this.subscribe();
    });
  },
  deactivated() {
    this.unsubscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    subscribe() {
      const vm = this;
      const user = (this.$store.state as any).auth.user;

      core.webSocket.subscribe(SubscribeType.CALL, {
        companyId: user.company.id,
        callback: (event) => {
          const crud = event.crud;
          const item = event.item;
          console.log("event : ", event);
          // item.incomingPhone
          // item.outgoingPhone
          // item.item
          const incomingPhone = item.incomingPhone;
          const outgoingPhone = item.outgoingPhone;
          vm.callList.some((call: any) => {
            if (call.incomingPhone == incomingPhone) {
              call.analysis = null;
              call.status = "";
              call.outgoingPhone = "";
              core.utils.copy(item, call);
              if (item.data != null && item.data.customerList) {
                call.customerList = item.data.customerList;
              } else {
                call.customerList = [];
              }
              call.dateText = "";
              call.callingAtText = "";
              call.startCallAtText = "";
              call.endCallAtText = "";
              call.absCallAtText = "";
              call.busyCallAtText = "";
              //console.log("call : ", call);
              call.date = core.date.instance().format("YYYY-MM-DD HH:mm:ss");
              return true;
            }
          });
        },
        connected() {},
      });

      core.webSocket.subscribe(SubscribeType.SUPPORT, {
        companyId: user.company.id,
        callback: (event) => {
          const crud = event.crud;
          const item = event.item;
          if (crud == "D") {
            this.supportMap.delete(item.estimateId);
          } else {
            if (item.activated) {
              this.supportMap.set(item.estimateId, item);
            } else {
              this.supportMap.delete(item.estimateId);
            }
          }
          this.supportSize = this.supportMap.size;
          //console.log("supportMap : ", this.supportMap);
        },
      });
    },
    unsubscribe() {
      const user = (this.$store.state as any).auth.user;
      core.webSocket.unsubscribe(SubscribeType.CALL, { companyId: user.company.id });
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    timeSecToString(sec) {
      let min = parseInt(String(sec / 60));
      let hour = parseInt(String(sec / 3600));
      sec = sec % 60;

      if (hour > 0) {
        return `${hour}시간 ${min}분 ${sec}초`;
      } else if (min > 0) {
        return `${min}분 ${sec}초`;
      } else if (sec > 0) {
        return `${sec}초`;
      } else {
        return "없음";
      }
    },
    showSupportAsPage() {
      window.open(`https://manager.smart-line.co.kr/main?page=support`, "_blank");
    },
    showViewPage(estimateId: number) {
      window.open(
        `https://manager.smart-line.co.kr/main?page=estimate&action=view&estimateId=${estimateId}`,
        "_blank"
      );
    },
    showAddPage(phone: string) {
      window.open(
        `https://manager.smart-line.co.kr/main?page=estimate&action=add&phone=${phone}`,
        "_blank"
      );
    },
    showSearchPage(phone: string) {
      window.open(
        `https://manager.smart-line.co.kr/main?page=estimate&action=search&phone=${phone}`,
        "_blank"
      );
    },
  },
});
