











import Vue from "vue";
import store from "@/store";
import core from "@/core";

export default Vue.extend({
  name: "App",

  data: () => ({
    transitionName: "forward",
    app: store.state.app,
    redirectPath: null as string | null,
    interval: null as number | null,
  }),

  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.updateMobileSystemProperties = this.updateMobileSystemProperties;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.updateMobileSettings = this.updateMobileSettings;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.updatePaymentSmsSettings = this.updatePaymentSmsSettings;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.socialLogin = this.socialLogin;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.mobileRedirectPath = this.mobileRedirectPath;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.showModal = this.showModal;

    window.addEventListener("resize", () => {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);
      this.app.size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      // document.body.style.minHeight = this.app.size.height + "px";
      document.documentElement.style.setProperty("--vh", `${this.app.size.height * 0.01}px`);
    });
    this.app.size = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    // document.body.style.minHeight = this.app.size.height + "px";
    document.documentElement.style.setProperty("--vh", `${this.app.size.height * 0.01}px`);
  },
  watch: {
    $route(to: any, from: any) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
      }
    },
    redirectPath() {
      if (this.interval == null && this.redirectPath != null) {
        const auth = this.$store.state.auth;
        const router = this.$router;

        this.interval = setInterval(() => {
          if (auth.user != null) {
            const path = this.redirectPath as string;
            this.redirectPath = null;
            console.log("redirect : ", path);
            try {
              router.push({
                path: path,
              });
            } catch (e) {
              console.log(e);
            }
          }
          if (this.redirectPath == null) {
            clearInterval(this.interval as number);
            this.interval = null;
          }
        }, 200);
      }
    },
  },
  methods: {
    showModal: function (params: any) {
      return core.alert.show(params);
    },
    mobileRedirectPath: function (path: string) {
      console.log("mobileRedirectPath : ", path);
      if (path != null && path.length > 0) {
        this.redirectPath = path;
      }
    },
    updateMobileSystemProperties: function (strSystemProperties: string) {
      const systemProperties = JSON.parse(strSystemProperties);

      this.$store.dispatch("auth/updateMobileSystemProperties", systemProperties);
    },
    updateMobileSettings: function (strSettings: string) {
      const mobileSettings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updateMobileSettings", mobileSettings);
    },
    updatePaymentSmsSettings: function (strSettings: string) {
      const settings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updatePaymentSmsSettings", settings);
    },
    socialLogin: function (strParam: string, addProvider: boolean | null) {
      //console.log(strParam);
      if (addProvider == null) addProvider = false;
      const token = JSON.parse(strParam);
      core.loader.show("로그인 중...");
      const params: any = {
        registrationId: "kakao",
        accessToken: token.accessToken,
        rememberMe: true,
      };
      if (addProvider) {
        params.addProvider = addProvider;
      }

      this.$store
        .dispatch("auth/socialLogin", params)
        .then(async (data) => {
          if (data.result) {
            console.log("call reload");
            window.location.reload();
          } else {
            //console.log(data);
            if (data.type === "addProvider") {
              core.loader.hide();
              const result = await core.alert.show({
                title: "확인",
                body: "이미 가입된 계정이 존재합니다.<br>소셜 로그인 정보를 추가하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });

              if (result === "confirm") {
                this.socialLogin(strParam, true);
              } else {
                core.alert.show({
                  title: "알림",
                  body: "가입된 계정 정보로 로그인 해주세요",
                });
              }
            } else {
              core.loader.hide();

              // 가입되지 않은 계정 회원가입 페이지로 이동
              const params = core.utils.deepCopy(data.socialUser);
              params.token = data.token;
              //console.log("params : ", params);
              this.$router.push({
                path: "/register",
                query: params,
              });
            }
          }
        })
        .catch((reason) => {
          core.loader.hide();
        });
    },
  },
});
