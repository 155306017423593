export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
}

export default class UpdateEvent {
  result: UpdateEventType;
  path: string;
  item?: any;

  constructor(result: UpdateEventType, path: string, item?: any) {
    this.result = result;
    this.path = path;
    this.item = item;
  }
}
