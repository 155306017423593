import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"h-100 grey lighten-3"},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{staticClass:"pa-2",attrs:{"align":"center","justify":"center"}},[(_vm.myUser.company.isManagerRole)?_c(VCard,{staticClass:"mb-2",attrs:{"width":"100%","rounded":"lg","ripple":{ class: 'primary--text' },"to":"/call-receive","outlined":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c(VCardActions,{staticClass:"pl-4"},[_c(VImg,{attrs:{"src":"/img/home/phone.png","width":"48"}})],1),_c('div',[_c(VCardTitle,{staticClass:"headline font-weight-bold pl-2"},[_vm._v(" 전화수신")]),_c(VCardSubtitle,{staticClass:"pl-2"},[_vm._v(" 실시간으로 수신되는 전화를 확인 할 수 있습니다. ")])],1),_c(VSpacer),_c(VCardActions,[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)]):_vm._e(),_c(VCard,{attrs:{"width":"100%","rounded":"lg","ripple":{ class: 'primary--text' },"outlined":""},on:{"click":_vm.logout}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c(VCardActions,{staticClass:"pl-4"},[_c(VImg,{attrs:{"src":"/img/home/logout.png","width":"48"}})],1),_c('div',[_c(VCardTitle,{staticClass:"headline font-weight-bold pl-2"},[_vm._v(" 로그아웃")]),_c(VCardSubtitle,{staticClass:"pl-2"},[_vm._v(" 현재 로그인된 정보를 해제합니다.")])],1),_c(VSpacer),_c(VCardActions,[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)])],1)],1),(_vm.modal.appShare.visible)?_c('ApplicationShareModal',{attrs:{"properties":_vm.modal.appShare}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }