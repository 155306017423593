import core from "@/core";
import UpdateEvent from "@/models/update-event";
import mixins from "vue-typed-mixins";

/**
 * 공통 처리
 */
export default mixins().extend({
  data: () => ({
    // 사용자 정보
    myUser: {
      id: 0,
      username: "",
      name: "",
      email: "",
      phone: "",
      roles: [] as string[],
      company: {
        name: "",
        role: "",
        lastApproval: {
          type: "",
        },
        isManagerRole: false,
        isAdminRole: false,
        isUserRole: false,
        scheduleRole: null as any as string,
        teamList: [] as any,
        options: {},
      },
    },
    // 모바일 여부
    isMobile: false,
    // 앱 사용 여부
    isApp: false,
    // 업데이트 이벤트 처리용 변수
    updateEvent: null as any as UpdateEvent | null,
    dateFormatPattern: "YYYY.MM.DD(ddd)",
    dateTimeFormatPattern: "YYYY.MM.DD(ddd) a hh:mm",
  }),
  created() {
    const platform = core.utils.platform();
    if (platform === "android" || platform === "ios") {
      this.isMobile = true;
    }
    this.isApp = core.mobile.isApp();

    const user = core.utils.deepCopy(this.$store.state.auth.user);
    if (user != null) {
      if (user.company != null) {
        user.company.isAdminRole = user.company.role === "OWNER" || user.company.role === "ADMIN";
        user.company.isManagerRole = user.company.isAdminRole || user.company.role === "MANAGER";
        user.company.isUserRole = user.company.role === "USER";
      }
      this.myUser = user;
    } else {
      this.myUser.roles.push("ANONYMOUS");
    }
  },
  methods: {
    isBlank(str) {
      return core.utils.validate.isBlank(str);
    },
    isNotBlank(str) {
      return core.utils.validate.isNotBlank(str);
    },
    moneyFormat(str: string) {
      return core.utils.format.moneyKor(str);
    },
    dateElapsedTimeFormat(date: string) {
      if (date != null) {
        return core.date.format.elapsedTime(date);
      }
      return "";
    },
    dateFormat(date: string) {
      return core.date.instance(date).format(this.dateFormatPattern);
    },
    dateTimeFormat(date: string) {
      return core.date.instance(date).format(this.dateTimeFormatPattern);
    },
    phoneFormat(phone: string) {
      return core.utils.format.hyphenPhone(phone);
    },
  },
});
