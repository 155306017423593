import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import core from "@/core";
import store from "@/store";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import Home from "@/views/Home.vue";
import CallReceive from "@/views/CallReceive.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "스마트라인",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "스마트라인 - 로그인",
      pageStack: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "스마트라인 - 로그아웃",
      roles: ["manager"],
      pageStack: true,
    },
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "스마트라인",
      roles: ["manager"],
      companyOptions: ["exists"],
      pageStack: false,
    },
  },
  {
    path: "/call-receive",
    name: "callReceive",
    component: CallReceive,
    meta: {
      title: "스마트라인",
      roles: ["manager"],
      companyOptions: ["exists"],
      pageStack: false,
    },
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "스마트라인",
      pageStack: false,
    },
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "스마트라인",
      pageStack: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

function routerResult(next: NavigationGuardNext, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  if (route != null && route.meta != null && route.meta.title != null) {
    return route.meta.title;
  } else "";
}

async function beforeEach(to: Route, from: Route, next: NavigationGuardNext) {
  const state: any = store.state;
  state.app.fromRoute = from;

  if (state.auth.user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.meta.companyOptions != null) {
        if (to.meta.companyOptions.indexOf("exists") > -1) {
          //console.log("user company exists ", state.auth.user.company != null);
          if (state.auth.user.company != null) {
            const approval = state.auth.user.company.lastApproval;
            if (approval != null && approval.type === "ALLOW") {
              next();
            } else {
              if (to.meta.companyOptions.indexOf("approval") > -1) {
                next();
              } else {
                return routerResult(next, "/company/waiting-approval");
              }
            }
          } else {
            return routerResult(next, "/company");
          }
        } else {
          if (state.auth.user.company != null) {
            return routerResult(next, "/");
          } else {
            next();
          }
        }
      } else {
        next();
      }
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        // 로그인중인 사용자가 로그인 페이지 접근시 이동
        return routerResult(next, "/");
      } else {
        next();
      }
    }
  } else {
    // 비로그인 중인 사용자 세션 확인
    console.log("check");
    const user = await store.dispatch("auth/getUser");
    console.log("user : ", user);
    if (user != null) {
      // 세션이 존재하면 로그인중인 사용자로 변경되어 재호출 처리
      await beforeEach(to, from, next);
    } else {
      if (to.meta.roles != null) {
        // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
        return routerResult(next, "/login");
      } else {
        next();
      }
    }
  }
  if (to.meta != null && to.meta.title != null) {
    return to.meta.title;
  } else {
    return "";
  }
}

let first = true;
router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);

  // core.loader.show();
  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/home" && to.path === "/home") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  if (first) {
    first = false;
    if (core.utils.redirectWebviewBrowser(router)) {
      return;
    }
  }

  // console.log("from " + from.path + " -> to : " + to.path);
  document.title = await beforeEach(to, from, next);
  // core.loader.hide();
});

export default router;
