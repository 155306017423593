

























































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import UserService from "@/services/user/user.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "Login",
  data: () => ({
    placeList: [],
    formProcessing: false,
    form: {
      rememberMe: true,
      username: "",
      password: "",
    },
  }),
  created() {
    // 생성 후 호출
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (!this.isMobile) {
        this.form.rememberMe = false;
      }
      const query = this.$route.query;
      if (core.utils.validate.isNotBlank(query.result)) {
        this.replaceState();
        if (query.result === "error") {
          const message = query.message as string;
          await core.alert.show({
            title: "알림",
            body: message,
          });
        } else if (query.result === "success") {
          const token = core.utils.getCookie("SOCIAL-TOKEN") as string;
          const result = await UserService.getSocialToken(token);
          core.utils.deleteCookie("SOCIAL-TOKEN");
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.socialLogin(JSON.stringify(result));
        }
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.formProcessing = true;

        try {
          await this.$store.dispatch("auth/login", this.form);
          await this.$router.push("/");
        } catch (e) {
          this.errorSubmit(e);
        }
        this.formProcessing = false;
      }
    },
    loginKakao() {
      if (this.isApp) {
        core.mobile.call({ cmd: "loginKaKao" });
      } else {
        // this.$router.push({ path: "/oauth2/authorize/kakao" });
        window.location.href = "/oauth2/authorize/kakao";
      }
    },
  },
});
