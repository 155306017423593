































































import core from "@/core";
import ApplicationShareModal from "@/modals/home/ApplicationShareModal.vue";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import ScheduleUtils from "@/models/schedule";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: { ApplicationShareModal },
  data: () => ({
    schedulePage: "/main?page=schedule",
    modal: {
      appShare: {
        visible: false,
      },
    },
  }),
  created() {
    this.$store.dispatch("storage/getStorageItem", "main").then((items) => {
      if (items != null && items.menu != null && items.menu.schedule != null) {
        this.schedulePage =
          "/main?" + ScheduleUtils.getStringUrlParamsFromMenu(items.menu.schedule);
      }
    });
  },
  mounted() {
    console.log("mounted");
  },
  activated() {
    console.log("activated");
  },
  methods: {
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
